import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { ReachAdapter } from 'use-query-params/adapters/reach';
import { QueryParamProvider } from 'use-query-params';

// If the pathname hasn't changed on an update, such as changing a query parameter
// then the page should not scroll to top.
export function shouldUpdateScroll({ prevRouterProps, routerProps }) {
	return prevRouterProps?.location?.pathname !== routerProps.location.pathname;
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
	return <QueryParamProvider adapter={ReachAdapter}>{element}</QueryParamProvider>;
};
